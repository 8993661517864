import React from 'react';
import {Layout} from 'antd';
import './AppFooter.style.less';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';

const {Footer} = Layout;

const AppFooter = () => {
  const {footer} = useLayoutContext();

  if (footer) {
    return (
      <Footer className='app-main-footer' style={{ marginTop: 100, padding: 10 }}>
        <p>Copyright &copy;2023 - <a href='https://divideindonesia.com' target='_blank' rel='noreferrer'>Divide Studio Indonesia</a></p>
      </Footer>
    );
  }
  return null;
};

export default AppFooter;
