import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const DetailList = React.lazy(() => import('./DetailList'));
const DetailListDetail = React.lazy(() => import('./DetailList/Detail'));
const DeliveryOrder = React.lazy(() => import('./DeliveryOrder'));
const DeliveryOrderForm = React.lazy(() => import('./DeliveryOrder/Form/Form'));
const Storage = React.lazy(() => import('./Storage'));
const Billet = React.lazy(() => import('./Storage/Billet'));
const BilletBundle = React.lazy(() => import('./Storage/BilletBundle'));
const BilletBundleDetail = React.lazy(() => import('./Storage/BilletBundle/Detail'));
const Movement = React.lazy(() => import('./Movement'));

export const warehousePageConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/warehouse/detaillist',
    element: <DetailList />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/warehouse/detaillist/detail/:id',
    element: <DetailListDetail />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/warehouse/deliveryorder',
    element: <DeliveryOrder />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/warehouse/deliveryorder/:id',
    element: <DeliveryOrderForm />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/warehouse/storage',
    element: <Storage />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/warehouse/storage/billet',
    element: <Billet />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/warehouse/storage/billetbundle',
    element: <BilletBundle />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/warehouse/storage/billetbundle/detail/:id',
    element: <BilletBundleDetail />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/warehouse/movement',
    element: <Movement />,
  },
];
