import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const Receiving = React.lazy(() => import('./Receiving'))
const Receive = React.lazy(() => import('./Receiving/Receive'))
const ReceivingDetail = React.lazy(() => import('./Receiving/Detail'))
const MTC = React.lazy(() => import('./MTC'))
const PhysicalChemicalTest = React.lazy(() => import('./PhysicalChemicalTest'))
const EMI = React.lazy(() => import('./EMI'))
const UT = React.lazy(() => import('./UT'))
const RoutingRolling = React.lazy(() => import('./RoutingRolling'))
const RoutingUpset = React.lazy(() => import('./RoutingUpset'))
const Upset = React.lazy(() => import('./Upset'))
const PipeVisualInspection = React.lazy(() => import('./PipeVisualInspection'))
const MPI = React.lazy(() => import('./MPI'))
const OutgoingInspection = React.lazy(() => import('./OutgoingInspection'))
const CouplingInspection = React.lazy(() => import('./CouplingInspection'))
const PipeThreading = React.lazy(() => import('./PipeThreading'))
const VisualInspection = React.lazy(() => import('./VisualInspection'))
const PhysicalChemicalTestRolling = React.lazy(() => import('./PhysicalChemicalTestRolling'))
const PhysicalChemicalTestHeatTreatment = React.lazy(() => import('./PhysicalChemicalTestHeatTreatment'))
const NCR = React.lazy(() => import('./NCR'))
const CirculationCard = React.lazy(() => import('./CirculationCard'))
const DriftRecheckForm = React.lazy(() => import('./DriftRecheckForm'))
const FinalInspection = React.lazy(() => import('./FinalInspection'));
const ThreadingLine = React.lazy(() => import('./ThreadingLine'))

export const qcPageConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/receiving',
    element: <Receiving />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/receiving/:id',
    element: <Receive />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/receiving/detail/:id',
    element: <ReceivingDetail />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/mtc',
    element: <MTC />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/physicalchemicaltest',
    element: <PhysicalChemicalTest />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/emi',
    element: <EMI />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/ut',
    element: <UT />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/routing-rolling',
    element: <RoutingRolling />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/routing-upset',
    element: <RoutingUpset />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/upset',
    element: <Upset />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/pipevisualinspection',
    element: <PipeVisualInspection />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/mpi',
    element: <MPI />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/outgoinginspection',
    element: <OutgoingInspection />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/couplinginspection',
    element: <CouplingInspection />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/pipethreading',
    element: <PipeThreading />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/visual-inspection',
    element: <VisualInspection />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/physicalchemicaltestrolling',
    element: <PhysicalChemicalTestRolling />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/physicalchemicaltestheattreatment',
    element: <PhysicalChemicalTestHeatTreatment />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/ncr',
    element: <NCR />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/circulation-card',
    element: <CirculationCard />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/DriftRecheckForm',
    element: <DriftRecheckForm />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/finalinspection',
    element: <FinalInspection />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/qc/threading-line',
    element: <ThreadingLine />,
  },
];
