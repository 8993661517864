/* eslint-disable */
import { useAuthMethod } from '@crema/utility/AuthHooks';
import { Dropdown, Menu } from 'antd';
import { useState } from 'react';
import { HiChevronDown, HiLogout, HiUser } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

export default function HeaderUser({ isMobile }) {
  const navigate = useNavigate();
  const {logout} = useAuthMethod();
  const user = JSON.parse(localStorage.getItem('user'));
  const role = localStorage.getItem('role_name');

  const [menuOnHover, setMenuOnHover] = useState(null);

  const styles = {
    menu: {
      basic: { padding: 7, borderRadius: 5 },
      hover: { padding: 7, borderRadius: 5, backgroundColor: '#eee' }
    }
  }

  const menuList = [
    {
      key: 'setting',
      label: 'Account Setting',
      icon: <HiUser size={15} />,
      link: () => navigate('/my-profile')
    },
    {
      key: 'logout',
      label: 'Logout',
      icon: <HiLogout size={15} />,
      link: () => logout()
    },
  ]

  const menu = (
    <Menu className='notify-header-message'>
      <Menu.Item className='header'>
        <span>{ user.name }</span> <br />
        <span style={{ fontSize: 12, textTransform: 'uppercase', color: '#aaa' }}>{ role }</span>
      </Menu.Item>
      <Menu.Item style={{ padding: '12px 20px' }}>
        <ul style={{ listStyle: 'none' }}>
          {menuList.map(menu => (
            <li 
              style={(menuOnHover == menu.key) ? styles.menu.hover : styles.menu.basic} 
              onMouseEnter={() => setMenuOnHover(menu.key)}
              onMouseLeave={() => setMenuOnHover(null)}
              onClick={() => menu.link()}
            >
              {menu.icon} {menu.label}
            </li>
          ))}
        </ul>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ marginLeft: 'auto' }}>

      <Dropdown overlay={menu} className='dropdown' trigger={['click']}>
        <a onClick={(e) => e.preventDefault()} style={{ display: 'flex', gap: 8, color: '#6b7280', alignItems: 'center' }}>
          <img src='/assets/images/placeholder.jpg' width={30} style={{ borderRadius: 150 }} />
          {!isMobile && (
            <>
              <span>{ user.name }</span>
              <HiChevronDown size={18} />
            </>
          )}
        </a>
      </Dropdown>

    </div>
  )
}