/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Menu} from 'antd';
import clsx from 'clsx';
import './index.style.less';
import defaultConfig from '../../../../utility/AppContextProvider/defaultConfig';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import {MenuStyle} from '../../../../../shared/constants/AppEnums';

import {Link} from 'react-router-dom';
import routesConfig from '../../../../../pages/routeConfig';
import axiosInstance from '@crema/services/axiosInstance';

const AppVerticalNav = () => {
  const {menuStyle, sidebarColorSet} = useSidebarContext();
  const {pathname} = useLocation();
  const selectedKeys = pathname.substr(1).split('/');
  const defaultOpenKeys = selectedKeys[0];
  const [openKeys, setOpenKeys] = useState([defaultOpenKeys]);
  const [allowedMenus, setAllowedMenus] = useState([]);

  const fetchMenu = () => {
    axiosInstance.get('/security/menus')
    .then(res => {
      const allowed = [];
      res.data.data.map(menu => allowed.push(menu.name));

      const menus = reformatMenu(routesConfig, allowed);
      setAllowedMenus(menus);
    });
  }

  const reformatMenu = (routes, allowed) => {
    const menus = [];
    routes.map(route => {
      let tmp = {};
      if (!route.moduleName) tmp = route;
      else if (allowed.includes(route.moduleName)) tmp = route;

      if (route.children && route.children.length > 0) {
        const reformat = reformatMenu(route.children, allowed);
        tmp.children = reformat;
      }

      if (tmp.children && tmp.children.length < 1) return;

      if (tmp.id) menus.push(tmp);
    });

    return menus;
  }

  useEffect(() => {
    setOpenKeys([selectedKeys[selectedKeys.length - 2]]);
    fetchMenu();
  }, []);

  function getStyles(item, sidebarColorSet, isSidebarBgImage, index, isGroup) {
    const {pathname, search} = useLocation();
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/');
    const fullPath = pathname + search;
  
    if (index === 0 || isGroup) {
      return {
        color: sidebarColorSet.sidebarTextColor,
        backgroundColor: isSidebarBgImage ? '' : sidebarColorSet.sidebarBgColor,
      };
    } else {
      let isActive = defaultOpenKeys[index] === item.id;
      if (!isActive && item.queryParams && pathname + item.queryParams == fullPath) isActive = true;
  
      return {
        color: isActive
          ? sidebarColorSet.sidebarMenuSelectedTextColor
          : sidebarColorSet.sidebarTextColor,
        backgroundColor: isActive
          ? sidebarColorSet.sidebarMenuSelectedBgColor
          : isSidebarBgImage
          ? ''
          : sidebarColorSet.sidebarBgColor,
      };
    }
  }

  const MenuComponent = () => {
    
    const renderMenu = (item, sidebarColorSet, isSidebarBgImage, index) => {
      return item.type === 'title' ? (
        <Menu.ItemGroup
          style={getStyles(item, sidebarColorSet, isSidebarBgImage, index, true)}
          key={item.path ? item.path : item.id}
          title={item.title}
        />
      ) : item.type === 'group' ? (
        <Menu.ItemGroup
          style={getStyles(item, sidebarColorSet, isSidebarBgImage, index, true)}
          key={item.path ? item.path : item.id}
          title={item.title}
        >
          {item.children.map((item) =>
            renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index + 1),
          )}
        </Menu.ItemGroup>
      ) : (
        <Menu.Item
          key={item.id}
          exact={item.exact}
          style={getStyles(item, sidebarColorSet, isSidebarBgImage, index)}
        >
          {item.children
            ? item.children
            : renderMenuItemChildren(
                item,
                sidebarColorSet,
                isSidebarBgImage,
                index
              )}
        </Menu.Item>
      );
    };

    const renderMenuItem = (item, sidebarColorSet, isSidebarBgImage, index) => {
      return item.type === 'collapse' ? (
        <Menu.SubMenu
          style={getStyles(item, sidebarColorSet, isSidebarBgImage, index, true)}
          key={item.path ? item.path : item.id}
          title={renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}>
          {item.children.map((item) =>
            renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index + 1),
          )}
        </Menu.SubMenu>
      ) : (
        <Menu.Item
          key={item.id}
          style={getStyles(item, sidebarColorSet, isSidebarBgImage, index)}>
          {item.children
            ? item.children
            : renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}
        </Menu.Item>
      );
    };

    const renderMenuItemChildren = (item) => {
      const {icon, messageId, path, title} = item;
    
      if (path && path.includes('/'))
        return (
          <Link to={path} style={{ paddingLeft: item.isChildNextLevel ? 20 : 0 }}>
            {icon &&
              (React.isValidElement(icon) ? (
                <span className='ant-menu-item-icon'>{icon}</span>
              ) : (
                <icon className='ant-menu-item-icon' />
              ))}
            <span data-testid={messageId.toLowerCase + '-nav'}>
              {title}
            </span>
          </Link>
        );
      else {
        return (
          <>
            {icon &&
              (React.isValidElement(icon) ? (
                <span className='ant-menu-item-icon'>{icon}</span>
              ) : (
                <icon className='ant-menu-item-icon' />
              ))}
            <span data-testid={messageId.toLowerCase + '-nav'}>
              {title}
            </span>
          </>
        );
      }
    };

    const {sidebarColorSet} = useSidebarContext();
    const {isSidebarBgImage} = useSidebarContext();

    return allowedMenus.map((route) =>
      renderMenu(route, sidebarColorSet, isSidebarBgImage, 0),
    );
  };

  return (
    <Menu
      theme={sidebarColorSet.mode}
      mode='inline'
      className={clsx('app-main-sidebar-menu ', {
        'menu-rounded': menuStyle === MenuStyle.ROUNDED,
        'menu-rounded rounded-menu-reverse':
          menuStyle === MenuStyle.ROUNDED_REVERSE,
        'menu-rounded standard-menu': menuStyle === MenuStyle.STANDARD,
        'menu-rounded curved-menu': menuStyle === MenuStyle.CURVED_MENU,
        'bg-color-menu':
          sidebarColorSet.sidebarBgColor !==
          defaultConfig.sidebar.colorSet.sidebarBgColor,
      })}
      defaultOpenKeys={openKeys}
      defaultSelectedKeys={[selectedKeys[selectedKeys.length - 1]]}
    >
      {MenuComponent(selectedKeys)}
    </Menu>
  );
};

export default AppVerticalNav;
