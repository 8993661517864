import axios from 'axios';
import { ApiUrl } from './apiUrl';
import { Modal, message } from 'antd';
import Unauthorized from '../../assets/icon/401.svg'
import ServerError from '../../assets/icon/500.svg'

const UnauthorizedContent = () => {
  return (
    <div>
      <img src={Unauthorized} height={30} />
      <p>You Have no Permission to take this Action.</p>
    </div>
  )
}

const ServerErrorContent = () => {
  return (
    <div>
      <img src={ServerError} height={30} />
      <p>We received this error. We`ll fix it immediately</p>
    </div>
  )
}

const axiosInstance = axios.create({
  baseURL: ApiUrl,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
    
    document.getElementById('app-spinner').style.display = 'flex';
    
    return config;
  },
  (error) => {
    document.getElementById('app-spinner').style.display = 'none';
    return Promise.reject(error);
  }
  );
  
axiosInstance.interceptors.response.use(
  (res) => {
    document.getElementById('app-spinner').style.display = 'none';
    
    return res;
  },
  (err) => {
    if (err?.response?.status == 401 && err?.response?.data?.message == 'UNAUTHORIZED') {
      Modal.error({
        title: '',
        content: <UnauthorizedContent />,
        centered: true,
      });
    } else if (err?.response?.status == 401 && err?.response?.data?.message == 'UNAUTHENTICATED') {
      localStorage.removeItem('user');
      localStorage.removeItem('role_name');
      localStorage.removeItem('name');
      localStorage.removeItem('token');
      window.location.href = '/signin';
    } else if (err?.response?.status == 500) {
      Modal.error({
        title: '',
        content: <ServerErrorContent />,
        centered: true,
      })
    } else {
      message.open({
        type: 'warning',
        content: err?.response?.data?.message,
        style: {
          zIndex: 99999999999999999999999999
        }
      })
    } 

    document.getElementById('app-spinner').style.display = 'none';
    return Promise.reject(err);
  }
);

export default axiosInstance;