import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const Vendor = React.lazy(() => import('./Vendor'));
const Grade = React.lazy(() => import('./Grade'));
const WorkOrder = React.lazy(() => import('./WorkOrder'));
const CreateWorkOrder = React.lazy(() => import('./WorkOrder/Create'));
const WoDetail = React.lazy(() => import('./WorkOrder/Detail'));
const Customer = React.lazy(() => import('./Customer'));
const ItemMaster = React.lazy(() => import('./ItemMaster'));
const Standard = React.lazy(() => import('./Standard'));
const ProcessCard = React.lazy(() => import('../processCard'));
const Approval = React.lazy(() => import('./Approval'));
const FileManager = React.lazy(() => import('./FileManager'));
const Equipment = React.lazy(() => import('./Equipment'));
const MaterialPicking = React.lazy(() => import('./MaterialPicking'));
const MaterialPickingForm = React.lazy(() => import('./MaterialPicking/Form'));

export const masterPageConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/vendor',
    element: <Vendor />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/grade',
    element: <Grade />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/workorder',
    element: <WorkOrder />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/workorder/create/:id?',
    element: <CreateWorkOrder />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/workorder/detail/:id',
    element: <WoDetail />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/customer',
    element: <Customer />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/itemmaster',
    element: <ItemMaster />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/standard',
    element: <Standard />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/process-card',
    element: <ProcessCard />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/approval',
    element: <Approval />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/file-manager',
    element: <FileManager />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: 'master/equipment',
    element: <Equipment />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/material-picking',
    element: <MaterialPicking />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/material-picking/create',
    element: <MaterialPickingForm />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/master/material-picking/:id',
    element: <MaterialPickingForm />,
  },
];
