import React from 'react';
import clsx from 'clsx';
import './index.style.less';
import {useThemeContext} from '../../../../utility/AppContextProvider/ThemeContextProvider';
import {useAuthUser} from '../../../../utility/AuthHooks';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import PropTypes from 'prop-types';

const UserInfo = ({hasColor}) => {
  const {themeMode} = useThemeContext();
  const {user} = useAuthUser();
  const {sidebarColorSet} = useSidebarContext();
  const {isSidebarBgImage} = useSidebarContext();

  return (
    <>
      {hasColor ? (
        <div
          style={{
            backgroundColor: isSidebarBgImage
              ? ''
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor,
          }}
          className={clsx('cr-user-info cr-user-info-hasColor', {
            light: themeMode === 'light',
          })}
        >
          <a className='cr-user-info-inner ant-dropdown-link'>
            <span className='cr-user-info-content'>
              <span className='cr-user-name-info'>
                <h3
                  className={clsx('cr-user-name text-truncate', {
                    light: themeMode === 'light',
                  })}>
                  {user.displayName}
                </h3>
              </span>
              <span className='cr-user-designation text-truncate'>
                {user.displayRole}
              </span>
            </span>
          </a>
        </div>
      ) : (
        <div
          className={clsx('cr-user-info', {
            light: themeMode === 'light',
          })}
        >
          <a className='cr-user-info-inner ant-dropdown-link'>
            <span className='cr-user-info-content'>
              <span className='cr-user-name-info'>
                <h3
                  className={clsx('cr-user-name text-truncate', {
                    light: themeMode === 'light',
                  })}>
                  {user.displayName}
                </h3>
              </span>
              <span className='cr-user-designation text-truncate'>
                System Manager
              </span>
            </span>
          </a>
        </div>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
};
